interface Record {
  id: string;
  env_id: string;

  name: string;
  endpoint: string;
  timeout: number;
  max_retry: number;
  event_types: Array<string>;

  created_at: string;
  updated_at: string;
}

interface Records extends Array<Record> {}

export interface GetDestinationResponse {
  records: Records;
  total_destination_count: number;
  total_switcher_count: number;
}

export const GetDestinationMockResponse: GetDestinationResponse = {
  records: [
    {
      id: 'c6828165-54e0-400e-ad29-dc34b4f23b44',
      env_id: '6ed426f4-392e-4a04-8ae3-ef767add306c',
      name: 'milogos CP',
      endpoint: 'https://example.com/milogos',
      timeout: 30,
      max_retry: 3,
      event_types: ['message', 'postback'],
      created_at: '2023-10-23T16:29:41.734296+0000',
      updated_at: '2023-10-23T16:29:41.734296+0000',
    },
    {
      id: '0308b9a5-ef54-4e09-b6ab-7ccdd7479078',
      env_id: '6ed426f4-392e-4a04-8ae3-ef767add306c',
      name: 'ミロゴスCP 2nd',
      endpoint: 'https://example.com',
      timeout: 30,
      max_retry: 1,
      event_types: ['friend', 'postback', 'beacon'],
      created_at: '2023-10-23T16:29:41.734296+0000',
      updated_at: '2023-10-23T16:29:41.734296+0000',
    },
    {
      id: '518a36a9-f992-43b7-8d75-9297f536e573',
      env_id: '6ed426f4-392e-4a04-8ae3-ef767add306c',
      name: 'ミロゴスCP 3rd',
      endpoint: 'https://example.com',
      timeout: 40,
      max_retry: 1,
      event_types: ['friend', 'postback', 'beacon'],
      created_at: '2023-10-23T16:29:41.734296+0000',
      updated_at: '2023-10-23T16:29:41.734296+0000',
    },
    {
      id: '96c8797f-b059-4b8f-bf17-67fec0de2660',
      env_id: '066ba03d-74e0-4e50-a62b-afce7d3d39c1',
      name: 'ミロゴスCP 4th',
      endpoint: 'https://example.com/milogos',
      timeout: 18,
      max_retry: 3,
      event_types: [],
      created_at: '2023-10-23T16:29:41.734296+0000',
      updated_at: '2023-10-23T16:29:41.734296+0000',
    },
    {
      id: 'abe650ae-d593-46f3-9a9c-695a0ff95869',
      env_id: '066ba03d-74e0-4e50-a62b-afce7d3d39c1',
      name: 'ミロゴスCP 5th',
      endpoint: 'https://example.com/milogos',
      timeout: 0,
      max_retry: 3,
      event_types: [],
      created_at: '2023-10-23T16:29:41.734296+0000',
      updated_at: '2023-10-23T16:29:41.734296+0000',
    },
  ],
  total_destination_count: 3,
  total_switcher_count: 37,
};

const records = [
  'c6828165-54e0-400e-ad29-dc34b4f23b44',
  '0308b9a5-ef54-4e09-b6ab-7ccdd7479078',
  '2a0a73bc-2d68-4120-8a7f-3f6331f1180d',
  '5eceff98-c3a5-482a-b2fd-1580a4d72ddd',
  '38388005-0a05-4db6-9e2f-608101e2e480',
  '5b525f1f-b93b-49e5-b371-51a8a5e3059a',
  '516f7d21-7286-4539-ad62-3c7154eb5414',
  'b4ca4aa9-9096-41ec-8b1a-b5b75a4b8667',
  '64ccd84b-faed-4053-a68a-b0d5e766f2a3',
  'f409ee35-b4e0-479e-bd62-9eed07653bdb',
  '122a9e50-b3dc-4233-90fa-da089bf2cb86',
  '0b4b172f-25d1-40f0-bc48-2b1fa12e445d',
  'abe650ae-d593-46f3-9a9c-695a0ff95869',
];
export const GetDestinationFullMockResponse: GetDestinationResponse = {
  records: [
    {
      id: '518a36a9-f992-43b7-8d75-9297f536e573',
      env_id: '066ba03d-74e0-4e50-a62b-afce7d3d39c1',
      name: 'ミロゴスCP 00',
      endpoint: 'https://example.com/milogos',
      timeout: 40,
      max_retry: 3,
      event_types: [],
      created_at: '2023-10-23T16:29:41.734296+0000',
      updated_at: '2023-10-23T16:29:41.734296+0000',
    },
    {
      id: '96c8797f-b059-4b8f-bf17-67fec0de2660',
      env_id: '066ba03d-74e0-4e50-a62b-afce7d3d39c1',
      name: 'ミロゴスCP 01',
      endpoint: 'https://example.com/milogos',
      timeout: 18,
      max_retry: 3,
      event_types: [],
      created_at: '2023-10-23T16:29:41.734296+0000',
      updated_at: '2023-10-23T16:29:41.734296+0000',
    },
    ...records.map((id, index) => ({
      id,
      env_id: '066ba03d-74e0-4e50-a62b-afce7d3d39c1',
      name: `ミロゴスCP ${index + 1}`,
      endpoint: 'https://example.com/milogos',
      timeout: 30,
      max_retry: 3,
      event_types: [],
      created_at: '2023-10-23T16:29:41.734296+0000',
      updated_at: '2023-10-23T16:29:41.734296+0000',
    })),
  ],
  total_destination_count: 15,
  total_switcher_count: 2,
};

export const GetDestinationEmptyMockResponse: GetDestinationResponse = {
  records: [],
  total_destination_count: 0,
  total_switcher_count: 0,
};

export const GetDestinationDetailResponse: Record = {
  id: 'c6828165-54e0-400e-ad29-dc34b4f23b44',
  env_id: '6ed426f4-392e-4a04-8ae3-ef767add306c',
  name: 'milogos CP',
  endpoint: 'https://example.com/milogos',
  timeout: 40,
  max_retry: 2,
  event_types: ['message', 'postback', 'friend'],
  created_at: '2023-10-23T16:29:41.734296+0000',
  updated_at: '2023-10-23T16:29:41.734296+0000',
};

export const GetDestinationDuplicateUrlMockResponse: GetDestinationResponse = {
  records: [
    {
      id: 'c6828165-54e0-400e-ad29-dc34b4f23b44',
      env_id: '6ed426f4-392e-4a04-8ae3-ef767add306c',
      name: 'milogos CP',
      endpoint: 'https://example.com',
      timeout: 40,
      max_retry: 3,
      event_types: ['message', 'postback'],
      created_at: '2023-10-23T16:29:41.734296+0000',
      updated_at: '2023-10-23T16:29:41.734296+0000',
    },
  ],
  total_destination_count: 1,
  total_switcher_count: 2,
};
